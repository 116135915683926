<template>
  <div class="taskInfo">
    <div class="taskInfo-layout">
      <van-cell-group :border="taskBorder">
        <van-cell title="项目名称" :value="formData.name"/>
        <van-cell title="发布企业" :value="formData.merchantName"/>
        <van-cell title="发布时间" :value="formData.createTime"/>
        <van-cell title="薪酬" :value="formData.salaryRange"/>
        <van-cell title="经验要求" :value="formData.experienceName"/>
      </van-cell-group>

      <van-cell-group>
        <van-cell title="项目描述：" />
        <van-cell title="" :label="formData.describe"/>
      </van-cell-group>
    </div>
    <van-button @click="signUpProject" :loading="btnLoading" loading-text="提交中..."  color="linear-gradient(to right, #ff6034, #ee0a24)" size="large" class="fixBtn">接受任务</van-button>
  </div>
</template>
<script>
import requestHTAPI from '@/axios/HantangTax'
export default {
  name: 'TaskInfo',
  data () {
    return {
      taskValue: 1,
      taskBorder: false,
      enGetTask: false,
      formData: {},
      btnLoading: false
    }
  },
  mounted () {
    this.initPage()
  },
  methods: {
    initPage () {
      const id = this.$route.query.id
      this.getOneProjectById(id)
    },
    getOneProjectById (id) {
      requestHTAPI.getOneProjectById({
        id
      }).then(res => {
        if (res.data.code === '0000') {
          this.formData = res.data.data
        } else {
          this.$notify({ type: 'danger', message: res.data.message })
        }
      })
    },
    signUpProject () {
      this.btnLoading = true
      requestHTAPI.signUpProject({
        id: this.formData.id,
        merchantId: this.formData.merchantId
      }).then(res => {
        this.btnLoading = false
        if (res.data.code === '0000') {
          this.$notify({ type: 'success', message: '操作成功' })
        } else {
          this.$notify({ type: 'danger', message: res.data.message })
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
  .taskInfo {
    /*overflow: hidden;*/
    /*height: 100%;*/
    background: #eee;
  }

  .taskInfo-layout {
    text-align: left;
    padding-top: 40px;
    background: #fff;
    padding-bottom: 150px;
  }

  .fixBtn {
    position: fixed;
    left: 0;
    bottom: 0;
  }
</style>
